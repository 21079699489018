export const patternPasswordForLogin =
  /^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~.]{8,}$/g;
export const patternPassword =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~.])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~.]{8,}$/g;
export const patternNumberAndHyphen = /^[0-9\-]+$/g;
export const patternNumberAndAlphabetsAndHyphen = /^[0-9a-zA-Z\-]+$/g;
export const SYMBOL_REGEX = /[!-\/:-@\[-`{-~¥\.]/g;
export const patternEmailAddress =
  /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
export const patternFreeEmailDomain =
  /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.|docomo|ezweb|softbank|i\.softbank|icloud|ybb|zoho)(([a-zA-Z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/;

// IPアドレスのバリデーション
export const ipV4Regex =
  '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';
export const IpV6OctateRegex = '[a-fA-F\\d]{1,4}';
export const ipV6Regex = `
(?:
(?:${IpV6OctateRegex}:){7}(?:${IpV6OctateRegex}|:)|                                   
(?:${IpV6OctateRegex}:){6}(?:${ipV4Regex}|:${IpV6OctateRegex}|:)|                             
(?:${IpV6OctateRegex}:){5}(?::${ipV4Regex}|(?::${IpV6OctateRegex}){1,2}|:)|                   
(?:${IpV6OctateRegex}:){4}(?:(?::${IpV6OctateRegex}){0,1}:${ipV4Regex}|(?::${IpV6OctateRegex}){1,3}|:)| 
(?:${IpV6OctateRegex}:){3}(?:(?::${IpV6OctateRegex}){0,2}:${ipV4Regex}|(?::${IpV6OctateRegex}){1,4}|:)| 
(?:${IpV6OctateRegex}:){2}(?:(?::${IpV6OctateRegex}){0,3}:${ipV4Regex}|(?::${IpV6OctateRegex}){1,5}|:)| 
(?:${IpV6OctateRegex}:){1}(?:(?::${IpV6OctateRegex}){0,4}:${ipV4Regex}|(?::${IpV6OctateRegex}){1,6}|:)| 
(?::(?:(?::${IpV6OctateRegex}){0,5}:${ipV4Regex}|(?::${IpV6OctateRegex}){1,7}|:))            
)(?:%[0-9a-zA-Z]{1,})?|(\:\:\d)                                            
`
  .replace(/\s*\/\/.*$/gm, '')
  .replace(/\n/g, '')
  .trim();
/* eslint-disable no-control-regex */
export const nullCharacterRegex = /\x00/g;

export const companyNamePattern = /^[\p{L}\p{N}].*$/u;
export const departmentNamePattern = /^[\p{L}\p{N}].*$/u;
export const positionNamePattern = /^[\p{L}\p{N}].*$/u;
export const sqlSelectRegex = /^\s*SELECT\s/i;

export const casNumberPattern = /^[0-9]{2,7}-[0-9]{2}-[0-9]$/;
export const patternPdfExtension = /^.+\.pdf$/i;
export const patternExtractFileExtension = /\.[^/.]+$/;

export const patternNoComma = /^[^,]*$/;
