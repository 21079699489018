export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const passwordHintForLogin =
  '8文字以上の大小半角英数字記号(!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~)で入力してください';
export const passwordHint =
  '8文字以上かつ半角英大文字・半角英小文字・半角数字・記号(!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~)をそれぞれ最低1回以上使用してください';

// 上限下限が存在しない場合は、-1000000, 1000000を設定する
export const TEMPERATURE_MINIMUM = -1000000;
export const TEMPERATURE_MAXIMUM = 1000000;

export const MAX_LIMIT = 99999;
